import Link from 'next/link';

export enum TooltipDirection {
    TopRight,
    BottomRight,
    // TODO: Support more directions
}

interface Props {
  children: React.ReactNode;
  text: string,
  direction?: TooltipDirection;
  href?: string;
  style?: string;
}

const Tooltip: React.FC<Props> = ({
  children,
  text,
  direction = TooltipDirection.TopRight,
  href,
  style,
}) => {

  const getSpanStyle = (): string => {
    switch (direction) {
      case TooltipDirection.TopRight:
        return styles.topRight;
      case TooltipDirection.BottomRight:
        return styles.bottomRight;
    }
  };

  return href ? (
    <Link
      href={href}
      className={style}
    >
      <p className={styles.p}>
        {children}
        <span className={getSpanStyle()}>
          {text}
        </span>
      </p>
    </Link>
  ):(
    <div className={style}>
      <p className={styles.p}>
        { children }
        <span className={getSpanStyle()}>
          { text }
        </span>
      </p>
    </div>
  );
};

export default Tooltip;

const styles = {
  p: "group relative inline-block duration-300",
  topRight: "absolute hidden group-hover:flex group-hover:justify-center -right-3 -top-2 -translate-y-full w-36 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-sm after:content-[''] after:absolute after:right-2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700",
  bottomRight: "mt-1 absolute hidden group-hover:flex group-hover:justify-center top-[100%] -right-3 w-40 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-sm after:content-[''] after:absolute after:right-2 after:bottom-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-t-transparent after:border-b-gray-700"

}
